<template>
 <div class="mt-10">
    <div class="vx-col">
      <vx-card class="mb-base">
        <div>
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
              <div class="w-full pr-4">
                <label class="vs-input--label">Room</label>
                <v-select :options="roomOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="roomFilter" class="mb-4 md:mb-0" />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
              <div class="w-full px-4">
                <label class="block mb-2">Child</label>
                <v-select :options="childrenOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="childrenFilter" class="mb-4 md:mb-0"/>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-divider cla ss="mb-0"></vs-divider>         
        <div id="diagram"></div>     
         <div class="flex justify-between items-center my-5">           
            <vs-button class="ml-auto" @click.stop="openPopup()">Add Interaction</vs-button>
          </div>          
      </vx-card>
    </div>
    
    <div class="diagram-toolTip" :style="position" v-show="showToolTip">
      <vs-card class="mb-0">
      <vs-row class="w-full">
        <vs-col class="flex w-full p-5">
          <div class=""> 
            <div class="img-container w-16 h-16 overflow-hidden rounded-full" style="height: 48px !important; width: 48px !important; overflow: hidden;">                   
              <img v-bind:src="tooltip.image"  alt="img" class="responsive object-cover w-full h-full">
            </div>
          </div>
          <div class="ml-4">
            <h4>{{tooltip.name}}</h4>
            <p>{{tooltip.interaction }} Interactions </p>
          </div>
        </vs-col>
      </vs-row>
      </vs-card>
    </div>
    


   <vs-popup fullscreen class="holamundo add-children-popup" title="Add Activity" :active.sync="popupActive">
      <h2 class="h1 mb-6">Add Interaction for {{ selectedChild.fullName }}</h2>

      <form>
        <vx-card class="mx-0">
          <vs-row>
            
            <vs-col vs-w="12">
              <vx-input-group :class="'mb-base ' + searchChild ? 'hasValue':''">
                  <vs-input  
                  icon-no-border
                  icon="search"
                  label-placeholder="Search" 
                  v-model="searchChild" 
                  name="searchChild" />
                </vx-input-group>
              </vs-col>  
            </vs-row>
            
            <vs-row>
              <vs-col vs-w="12">
                <ul>
                  <li>
                    <div class="round" style="display: flex; align-items: center">
                      <input :id="'checkbox-a'" type="checkbox" @click="selectAllChildren(childrenToggle)" />            
                      <label :for="'checkbox-a'">
                        <i class="feather icon-check"></i>
                      </label>
                      <span style="margin-left: 23px; font-size: 16px; line-height: 22px;">Select All</span>
                    </div>
                  </li>
                  
                  <li v-for="(child, index) in searchChildrenInteractionList" :key="index">          
                    
                    <div class="round" style="display: flex; align-items: center">
                                
                        <input 
                        :id="'checkbox-'+index" 
                        name="interactionChild" 
                        type="checkbox" 
                        v-model="interactionChildren" 
                        :value="child"
                        />
                         <label :for="'checkbox-'+index">
                          <i class="feather icon-check"></i>
                        </label>
                           <vs-avatar size="46px" :src="child.image" style="margin-left: 22px;"/>
                          <span style="margin-left: 6px; font-size: 16px; line-height: 22px;" >
                          {{ child.label }}</span>
                    </div>
                  </li>
                </ul>
               </vs-col>
              </vs-row>  

           
          
          
          <div class="flex justify-between mt-20">
            <vs-button class="mr-3 mb-2" color="primary" type="filled" @click="addChildInteractionData">Save</vs-button>
          </div>
        </vx-card>
      </form>
    </vs-popup>
  </div>

</template>




<script>
  import vSelect from 'vue-select'
  import {mapActions} from "vuex";
  import * as d3 from "d3";
  import _ from 'lodash';

  export default {
    components: {
      vSelect,
      d3,
      
    },
    data() {
      return {
        mouseX: '',
        mouseY: '',
        position: {},
        showToolTip: false,
        tooltip: {
          name: '',
          image:'',
          interaction:'',
        },
        relationshipCircleDiagram:{},
        relationshipCircleList:{},
        relationshipCircleChildrenList:{},
        relationshipCircleChildrenList_bk:{},
        roomDetail:{},
        roomOptions: [],
        childrenOptions: [],
        childrenInteractionList: [],
        searchChildrenInteractionList: [],
        searchChild: '',
        selectedChild: {},
        interactionChildren: [],
        childrenToggle: false,
        selectedChildren: [],
        roomFilter: { label: 'Select Room', value: ''},        
        childrenFilter: { label: 'Select Child', value: ''},
        svgProp: {
          width: 600,
          height: 600
        },
        popupActive: false,

      }
    },

    computed: {
      director(){
        return this.$store.state.AppActiveUser;
      },
      positionCalc: function() {
        return {
            top: `${this.mouseY + 10}px`,
            left: `${this.mouseX + 10}px`
        }
      },

      svgChart(){        
        const self = this;
       const myNode = document.getElementById("diagram");
        myNode.innerHTML = '';

        myNode.innerHTML = '';          
       if( _.size( self.relationshipCircleChildrenList ) <= 0 ){          
          myNode.innerHTML = 'No Activities available';
          this.$vs.loading.close()
          return 0;
        }

        const radiusScale = d3.scaleSqrt().domain( [1,300]).range([10,80]);
      /*   const simulation = d3.forceSimulation()
              .force("x", d3.forceX(  this.svgProp.width/2).strength(0.05) )
              .force("y", d3.forceY( this.svgProp.height/2).strength(0.05) )
              .force( "collide",d3.forceCollide( (d) => {
                console.log( 'trest',d );
                return radiusScale( d.value ) +1
              })) */
        const bubble = d3.pack()
              .size([this.svgProp.width, this.svgProp.height])
              .padding(3);

        const svg = d3.select('#diagram')
                .append('svg')
                .attr('width', this.svgProp.width)
                .attr('height', this.svgProp.height)
                .attr("class", "bubble");

        const defs = svg.append('defs');

        const nodes = d3.hierarchy(  this.processSvgData() )
            .sum(function(d) { return d.interaction; });

        const node = svg.selectAll(".node")
                .data(bubble(nodes).descendants())
                .enter()
                .filter(function(d){
                    return  !d.children
                })
                .append("g")
                .attr("class", "node")
                .attr("transform", function(d) {
                    return "translate(" + d.x + "," + d.y + ")";
                });

            defs.selectAll(".child-pattern")
              .data(bubble(nodes).descendants())
              .enter().append('pattern')
              .attr("class", "child-pattern")
              .attr("id", (d) => {
                return d.data.className;
              })
              .attr("height", "100%")
              .attr("width", "100%")
              .attr("patternContentUnits","objectBoundingBox")
              .attr("class","child-pattern" )
              .append("image")
              .attr("id", (d) => {
                return d.data.className;
              })
              .attr("height", "1")
              .attr("width", "1")
              .attr("preserveAspectRatio", "xMidYMin slice")
              .attr("viewBox", "0 0 60 55")
              .attr("xmlns:xlink", "https://www.w3.org/1999/xlink")
              .attr("xlink:href", (d)=> {
                return d.data.image;
              })
              .attr("x", 0)
              .attr("y", 0);

          node.append("title")
            .text(function(d) {
                return d.data.name + ": " + d.data.interaction;
            });         
         /*  node.append("data-tooltip")
            .text(function(d) {
                return "<div>"+d.data.name + ": " + d.data.interaction+"</div>";
            });   */       

          node.append("circle")
                  .attr("r", function(d) {
                      return d.r;
                  })
                  .style("fill", function(d) {
                      return "url(#" + d.data.className + ")";
                  })
                  .on('mouseover',(e,d) => {                       
                      // console.log('hello', d,e );
                      self.tooltip = {
                        name: d.data.name,
                        image:d.data.image,
                        interaction:d.data.interaction,
                      };
                      self.mouseX = e.pageX;
                      self.mouseY =  e.clientY;
                      self.showToolTip = true;

                  }).on('mouseout', ()=> {
                    self.showToolTip = false;
                  });

          //  simulation.nodes( bubble(nodes).descendants() );

         /*  node.append("text")
                  .attr("dy", ".3em")
                  .style("text-anchor", "middle")
                  .text(function(d) {
                    console.log( d);
                      return d.data.name.toLowerCase().substring(0, d.r / 3) + ": " + d.data.interaction;
                  }); */

          d3.select(self.frameElement)
                  .style("height", this.svgProp.height + "px");

          this.$vs.loading.close()
        return 0;
        },

    },
    methods: {
      ...mapActions("center", [
        "getDirectorRooms",
      ]),
      ...mapActions("child", [
        "fetchChildrenByRoomId",
      ]),
      ...mapActions("activity", [
        "getChildInteractionList",
      ]),
      ...mapActions("interaction", [
        "saveInteraction",
        "getInteraction"
      ]),

      processSvgData() {          

          let newDataSet = [];
          let self = this;
          _.forEach( this.relationshipCircleChildrenList, (child, k) => {
              newDataSet.push({
                name: child.fullName,
                className: child.fullName.toLowerCase().replace(/ /g,'') + '-' + (("undefined" != typeof child.interaction) ? child.interaction : 0),
                interaction:  ("undefined" != typeof child.interaction) ? child.interaction : 0,
                image: ("undefined" != typeof child.photo && 'test.jop' != child.photo && '' != child.photo ) ? child.photo : this.$defaultImage
            } );
          });

          return {children: newDataSet};
      },
     
      async getChildrenByRoom(roomId){
        let childrenList = await this.fetchChildrenByRoomId( roomId );
        childrenList = _.map(childrenList.data.data, o  => {
          return {
            label: o.fullName,
            value: o._id,
            image: o.profileImage
          };
        });
        if( _.size(childrenList) > 0 ){

          childrenList = _.sortBy( childrenList, (o) => {
            return o.label;
          });
          this.childrenOptions  = childrenList;
          this.childrenInteractionList = childrenList;
          this.searchChildrenInteractionList = childrenList;
          if( _.size( this.childrenOptions ) > 0  ) {
            this.childrenFilter= { label: this.childrenOptions[0].label, value: this.childrenOptions[0].value};
          } 
        } else {
          this.childrenFilter= { label: '', value: '' };
          this.childrenOptions = []
        }
      },
      async getChildInteraction( selectedChildId ){
        let query = {
          roomId: this.roomFilter.value,
          childId: selectedChildId
        };
        let circleList = await this.getChildInteractionList(query);
          circleList = circleList.data.data;

           if(_.size(circleList) <= 0 ) {
          this.relationshipCircleChildrenList = {};
          await this.svgChart;
          return 0;
        }


        let teacherInteraction = await this.getInteraction(selectedChildId );
        teacherInteraction = teacherInteraction.data.data.docs;
         

        
        let getChildren = [];
         _.forEach( circleList, (elem) => {
            if( "undefined" != typeof elem.children ) {
              _.forEach( elem.children, ( child,k ) => {

                if( _.size( getChildren ) > 0 ) {

                  if( "undefined" == typeof _.find( getChildren, ["_id", child.childId ] ) ) {
                    child.childDetail.interaction = 0;
                    getChildren.push( child.childDetail );
                  }

                  let index = _.findIndex( getChildren,["_id", child.childId ] );
                  if( -1 != index ){
                    if( "undefined" != typeof getChildren[index].interaction ) {
                      getChildren[index].interaction = parseInt( getChildren[index].interaction ) + 1;
                    } else {
                      getChildren[index].interaction = 1;
                    }
                  }
                } else {
                    child.childDetail.interaction = 0;
                  getChildren.push( child.childDetail );
                }
              });
            }
        });

        getChildren = _.uniqBy(getChildren, '_id');

        let index = _.findIndex( getChildren,["_id", selectedChildId ] );
        if( -1 != index ){
          getChildren[index].selectedChild = true;
          this.selectedChild = getChildren[index];          
        }  
        
        this.relationshipCircleChildrenList = getChildren;
        
        let self = this;
        self.relationshipCircleChildrenList_bk = self.relationshipCircleChildrenList;
        if( teacherInteraction.length > 0  ) { 
         _.forEach( teacherInteraction[0].interactionWith, (elem) => {
           let key = _.findIndex( self.relationshipCircleChildrenList,[ "_id", elem.childId ] );                  
          
              if( "undefined" != typeof key ) {
                if ( "undefined" != typeof self.relationshipCircleChildrenList[key] ) {
                  
                  self.relationshipCircleChildrenList[key].interaction =  parseInt( self.relationshipCircleChildrenList[key].interaction ) + parseInt(elem.interactionPoint * 3);
                }          
                  
              }
          });
        }   
        /** call svg function to generate diagram */
       await this.svgChart;
      },
      
      async addChildInteractionData(){
        let self = this;
        this.$validator.validateAll().then((result) => {
          if (result) {
            let childIds = _.map( this.interactionChildren, "value");

            let data = new FormData();
            data.append('learningCenterId', self.director.learningCenterId )

            if ( childIds.length > 0 ) {
              for (var i = 0; i < childIds.length; i++) {
                data.append("childrens[]", childIds[i]);
              }
            }

            data.append( 'selectedChild', this.selectedChild._id );            
            
            this.saveInteraction(data).then( async (response) => {
              this.$vs.loading();

               await this.$vs.notify({
                  title: "Success",
                  text: "Interaction added Successfully",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",
              });


             self.getChildInteraction( this.selectedChild._id  )

              /* 
                let teacherInteraction = await self.getTeacherInteraction( self.selectedChild._id );
                let interactionWith = teacherInteraction.data.data.docs;
                if( interactionWith.length > 0  ) { 
                  self.relationshipCircleChildrenList = self.relationshipCircleChildrenList_bk;


                  await _.forEach( interactionWith[0].interactionWith, (elem) => {
                    let key = _.findIndex(  self.relationshipCircleChildrenList,["_id", elem.childId ] );                  
                      if( "undefined" != typeof key ) {
                        if ( "undefined" != typeof self.relationshipCircleChildrenList[key] ) {
                          
                          self.relationshipCircleChildrenList[key].interaction = parseInt( self.relationshipCircleChildrenList[key].interaction ) + parseInt(elem.interactionPoint *3);
                        
                        }
                      }
                  });
                }

              
             
              await this.svgChart; */
              self.popupActive = false;
              self.$vs.loading.close()

              
            }) .catch((err) => {            
              self.$vs.loading.close()
             /*  this.$vs.notify({
                title: "Error",
                text: "Please fix the errors",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });     */         
            });

          self.popupActive = false;
          // self.$forceUpdate();
                      /* this.$router.push({
                name: "friendship-circle",                
              }); */
          }
        })  


      },
      closePopup(){
        this.popupActive = false;
      },
      openPopup() {        
        this.popupActive = true;
      },
      selectAllChildren(res) {
         
        this.childrenToggle = !res;
        if ( this.childrenToggle ) {
          this.interactionChildren = this.searchChildrenInteractionList;
        } else {
          this.interactionChildren = [];
        }
      },
    },
    watch: {
      roomFilter(obj) {
        this.roomDetail = obj;
        this.getChildrenByRoom( this.roomDetail.value);
      },
      childrenFilter(obj){
        this.getChildInteraction( obj.value );
      },

      relationshipCircleChildrenList(){
        this.svgChart;
      },

      showToolTip(obj) {
        if( obj ){

          this.position = this.positionCalc
    
        } else {
          this.tooltip = {
                          name:'',
                          image:'',
                          interaction:0,
                        };
          this.mouseX = '';
          this.mouseY = '';
        
        }

      },
      searchChild(obj) {
        if( obj ) {

          this.searchChildrenInteractionList = _.filter( this.childrenInteractionList,(elem ) => {            
            return elem.label.toLowerCase().indexOf( obj ) >= 0;
          });

        } else {
          this.searchChildrenInteractionList = this.childrenInteractionList
        }
      }
    },
    mounted() {
    },

    created() {
       this.$vs.loading();
      this.getDirectorRooms(this.director.id)
        .then( (res) => {
            this.roomOptions = res.data.data;

            this.roomFilter= { label: this.roomOptions[1].name, value: this.roomOptions[1]._id};

        })
        .catch(err => {
        })
    }

  }


</script>

<style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .deleteBtn {
    background-color: white;
    color: black;
    border: 2px solid #f44336;
  }

  .diagram-toolTip {
      position: absolute;
      min-width: 250px;
      pointer-events: none;
      .vs-card--content{
        padding: 0 !important;
        margin-bottom: 0 !important;
      }
  }

  .diagram-toolTip > p {
      color: #000;
      text-align: left;
      padding: 1px;
      margin: 1px 10px 1px 10px;
  }



.add-children-popup,
.tag-children-popup {
  .vs-popup {
    @media (min-width: 992px) {
      width: 825px;
    }
  }

  .vs-popup--header {
    height: 0;
  }

  .vs-popup--close {
    transform: none !important;
    background: none !important;
    box-shadow: none;
    position: relative;
    top: 35px;
    right: 20px;
    font-size: 25px;
    color: #2b2d3d;

    &:hover {
      color: #2d9ad5;
    }
  }

  .vs-popup--content {
    padding: 45px 35px !important;
    margin: 0;
    width: 100%;

    .vx-card {
      box-shadow: none;

      .vx-card__body {
        // padding: 10px !important;
        border-radius: 8px !important;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);

        .vs-con-input-label.is-label-placeholder {
          margin-top: 0 !important;
          margin-bottom: 25px;
        }
      }
    }
  }

  .vs-checkbox-primary {
    .vs-checkbox {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid #289ad5 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      overflow: hidden;
      transform: none;
    }
  }

  .round {
    label {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #289ad5 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      transform: none;
      margin: 0 5px;
      background: transparent;
      transition: 0.4s all ease-in-out;

      i {
        opacity: 0;
      }
    }
  }
}

.add-children-popup {
  .vs-checkbox-primary {
    .vs-checkbox {
      margin-right: 5px;
      border: none !important;

      .vs-checkbox--check {
        border: 1px solid #2d9ad5 !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: none;
        transition: 0.4s all ease-in-out;
      }

      .vs-icon {
        opacity: 0;
      }
    }
  }
}

.add-children-popup
.vs-checkbox--input:checked
+ .vs-checkbox
.vs-checkbox--check {
  transform: none;
  top: 0;
  left: 0;
  border: 10px solid #2d9ad5 !important;
  width: 100%;
  height: 100%;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.add-children-popup .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  transform: none;
  opacity: 1;
}

.add-children-popup ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.add-children-popup .vs-checkbox-primary .con-slot-label {
  margin-left: 6px;
  font-size: 16px;
  line-height: 22px;
}


</style>
